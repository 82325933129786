<div class="auth-container">

  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card">

      <div class="icon-container">
        <app-svg-sprite icon="envelope" classes="tw-h-64-px"></app-svg-sprite>
      </div>

      @if (!isVerificationEmailSent) {
        <div class="content-container">
          <h3 class="title">{{ title }}</h3>
          @if (!hasTokenExpired) {
            <p class="description">
              An activation link has been sent to
              @if (email) {
                <strong class="tw-text-16-px"> {{ email }}</strong>
              }
              @if (!email) {
                <span class="tw-text-16-px"> your email. </span>
              }
              Please click on it to verify your account and proceed with {{ targetConfig.BRAND_NAME }}.
            </p>
          }
          @if (!hasTokenExpired) {
            <p class="description-2">
              Didn't receive the email?
              @if (email) {
                <a (click)="resendVerification()" class="tw-cursor-pointer tw-text-16-px">Resend</a>
              }
              @if (!email) {
                <span class="tw-text-16-px">Please use the below form to request it again.</span>
              }
            </p>
          }
          @if (hasTokenExpired) {
            <p class="description-2">
              Please use the below form to request the verification link again.
            </p>
          }
          @if (!email) {
            <form class="form-container" novalidate>
              <div class="email-input-container">
                <input
                  [formControl]="emailForm"
                  autocomplete="off"
                  type="email"
                  placeholder="Email"
                  class="input-text input-content"
                  [ngClass]="{'p-error' : emailForm.touched && emailForm.invalid}"
                  appAutofocus>
                <div class="error-space">
                </div>
                @if (emailForm.touched && emailForm.invalid) {
                  @if (emailForm.hasError('required')) {
                    <div class="error">Please enter an email address</div>
                  }
                  @if (emailForm.hasError('email')) {
                    <div class="error">Please enter a valid email address</div>
                  }
                }
              </div>
              <ui-button
                [defaultType]="'submit'"
                [buttonType]="buttonType.PRIMARY"
                (buttonClick)="resendVerification()"
                [isLoading]="showLoaderOnButton"
                [loadingText]="'Resending'"
                [label]="'Resend Verification Link'"
                [validateForm]="true"
                >
              </ui-button>
            </form>
          }
        </div>
      }

      @if (isVerificationEmailSent) {
        <div class="tw-text-center">
          <h3 class="title">Verification email sent</h3>
          <p class="description">
            An activation link has been sent to
            @if (email) {
              <strong class="tw-text-16-px"> {{ email }}</strong>
            }
            @if (!email) {
              <span class="tw-text-16-px"> your email. </span>
            }
            Please click on it to verify your account and proceed with {{ targetConfig.BRAND_NAME }}.
          </p>
        </div>
      }
    </div>

    <div class="alternate-text">
      Back to <a href="#/signin?asset=webapp">Sign In</a>
    </div>
  </div>
</div>

<app-toast-message></app-toast-message>