import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgSpriteComponent } from '@fyle/svg-sprite';
import { ModalHeaderTypes } from './modal-header-type.enum';

@Component({
  selector: 'ui-modal-header',
  standalone: true,
  imports: [CommonModule, SvgSpriteComponent],
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class FdlModalHeaderComponent {
  @Input() type: ModalHeaderTypes = ModalHeaderTypes.NORMAL;

  @Input() title: string = '';

  @Output() closeModal = new EventEmitter<void>();

  modalHeaderTypes = ModalHeaderTypes;

  close() {
    this.closeModal.emit();
  }
}
