import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SvgSpriteComponent } from '@fyle/svg-sprite';
import { AlertTypes } from './alert-types.enum';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'ui-alert',
  templateUrl: './alert.component.html',
  standalone: true,
  styleUrls: ['./alert.component.scss'],
  imports: [CommonModule, SvgSpriteComponent],
  animations: [
    trigger('fadeAndCollapseOnLeave', [
      transition(':leave', [
        animate(
          '200ms cubic-bezier(0.86, 0, 0.07, 1)',
          style({
            opacity: 0,
            height: 0,
            padding: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class FdlAlertComponent {
  @Input({ required: true }) type: AlertTypes;

  @Input() message: string;

  @Input() header: string;

  @Input() showIcon: boolean = true;

  @Input() dismissible: boolean = false;

  @Output() dismiss = new EventEmitter<void>();

  alertTypes: typeof AlertTypes = AlertTypes;

  displayAlert: boolean = true;

  dismissAlert() {
    this.displayAlert = false;
    this.dismiss.emit();
  }
}
