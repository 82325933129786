<!-- 'fdl-styles' class contains all the styles we need for styling p-dropdown and p-dropdown-items,
applying the class to root element i.e. p-dropdown and passing it in panelStyleClass for applying styles on dropdown items -->

<p-dropdown
  #pDropdown
  name="dropdown"
  [formControl]="dropdownControl"
  [options]="options"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [filter]="showSearch"
  [filterBy]="searchByKeys"
  [autoDisplayFirst]="autoDisplayFirst"
  [resetFilterOnHide]="true"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [lazy]="lazy"
  (onChange)="onChange($event)"
  (onBlur)="onBlur($event)"
  (onShow)="onPanelShow()"
  (onLazyLoad)="onLazyLoad($event)"
  autocomplete="off"
  [placeholder]="placeholder"
  [attr.placeholder]="placeholder"
  class="fdl-styles"
  styleClass="size-{{size}} {{styleClass || 'tw-w-full'}}"
  panelStyleClass="fdl-styles {{panelStyleClass || 'tw-w-full'}}"
  [appendTo]="'body'">

  <ng-template pTemplate="item" let-item>
    <!-- Custom item template passed from consumer component will be rendered here -->
    @if (itemTemplateRef) {
      <ng-template [ngTemplateOutlet]="itemTemplateRef" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
    } @else {
      <div class="item">
        <div>{{optionLabel ? item[optionLabel] : item}}</div>
      </div>
    }

  </ng-template>


  <ng-template pTemplate="filter" let-options="options">
    <div class="search-container">
      <input
        autocomplete="off"
        [attr.placeholder]="dropdownSearchPlaceholder"
        class="search-input"
        type="text"
        (input)="searchHandler($event, options)">
      <ui-svg-sprite [icon]="'search'" [classes]="'icon-search'"></ui-svg-sprite>
    </div>
  </ng-template>

  <ng-template pTemplate="dropdownicon">

    @if ((showClearIcon && !dropdownControl.value) || !showClearIcon) {
      <div>
        <ui-svg-sprite [icon]="'arrow-down'" [containerClasses]="'tw-h-18-px'" [classes]="'tw-h-18-px tw-w-18-px tw-fill-icon-muted'" ></ui-svg-sprite>
      </div>
    }
    @if (showClearIcon && dropdownControl.value) {
      <div>
        <ui-svg-sprite (click)="clearFilter($event)" [icon]="'cross'" [containerClasses]="'tw-h-18-px'" [classes]="'tw-h-18-px tw-w-18-px tw-fill-icon-muted'"></ui-svg-sprite>
      </div>
    }
  </ng-template>

  @if (footerTemplateRef) {
    <ng-template pTemplate="footer">
      <ng-template [ngTemplateOutlet]="footerTemplateRef"></ng-template>
    </ng-template>
  }

  @if (selectedItemTemplateRef; as option) {
    <ng-template pTemplate="selectedItem" let-option>
      <ng-template [ngTemplateOutlet]="selectedItemTemplateRef" [ngTemplateOutletContext]="{$implicit: option}"></ng-template>
    </ng-template>
  }

  <!-- TODO: Aniruddha to add empty state template -->

  <!-- TODO: Aniruddha to implement loader template once design is ready -->
</p-dropdown>